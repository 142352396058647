import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ComingSoonPage from './ComingSoonPage'
import ErrorPage from './ErrorPage'
import LandingPage from './LandingPage'
import LoadingPage from './LoadingPage'
import { useGlobalState } from './State'
function Router({ children }) {
        const globaleState = useGlobalState()
        const maintenanceRoutes = [
                {
                        path: '/',
                        element: <ComingSoonPage />,
                        errorElement: <ComingSoonPage />,
                },

                {
                        path: '/clientaccess',
                        element: <LandingPage />,
                },
        ]
        const routes = [
                {
                        path: '/',
                        element: <LandingPage />,
                        errorElement: <ErrorPage />,
                },
                {
                        path: '/maintenance',
                        element: <ComingSoonPage />,
                },
        ]

        const getRoutes = () => {
                if (globaleState.maintenanceMode) return maintenanceRoutes
                return routes
        }

        const router = createBrowserRouter(getRoutes())

        return (
                <RouterProvider
                        router={router}
                        fallbackElement={<LoadingPage />}
                ></RouterProvider>
        )
}

export default Router
