import React from 'react'
import styled from 'styled-components'
import imgProfile from '../../../assets/images/Profile.jpg'

const AboutSection = styled.section`
        position: relative;
        min-height: 20rem;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(
                180deg,
                rgba(248, 248, 248, 1) 0%,
                rgba(212, 212, 212, 1) 75%
        );
`

const MaxWidthSection = styled.div`
        width: min(90%, ${({ theme }) => theme.screens.desktop});
        margin: max(5vh, 4rem) auto;
        display: flex;
        flex-direction: row-reverse;
        border-radius: 0.2rem;
        ${({ theme }) => theme.shadows.z3};
        overflow: hidden;
        flex-wrap: wrap;
        @media (max-width: ${({ theme }) => theme.screens.tablet}) {
                flex-direction: column;
        }
`
const ImgBox = styled.div`
        position: relative;
        flex: 1;
        min-height: 30rem;
        background-image: url(${imgProfile});
        background-size: cover;
        background-position: center;
`
const TextBox = styled.div`
        flex: 0 1 420px;
        flex: 1;
        padding: 2rem;
        h2 {
                color: ${({ theme }) => theme.colors.brand.base};
        }
        background-color: ${({ theme }) => theme.colors.foundation.white};
`

function About() {
        return (
                <AboutSection id="about">
                        <MaxWidthSection>
                                <TextBox>
                                        <h2>About Janine</h2>
                                        <p>
                                                Janine, a 2002 graduate with a
                                                BSc (Honours) in Physiotherapy
                                                from the University of Cape
                                                Town, has worked in diverse
                                                public and private healthcare
                                                settings in South Africa and the
                                                United Kingdom.
                                                <br />
                                                <br />
                                                Boasting over 20 years of
                                                experience, Janine is highly
                                                accomplished. Her approach
                                                focuses on movement control and
                                                dysfunction, which she believes
                                                is crucial for pain reduction,
                                                injury management and
                                                prevention, and achieving
                                                patient goals. She stays updated
                                                with the latest research by
                                                regularly completing courses to
                                                enhance her knowledge and
                                                deliver evidence-based
                                                treatments.
                                                <br />
                                                <br />
                                                In addition to her professional
                                                life, Janine is a married mother
                                                of two boys who inspire her to
                                                maintain an active and healthy
                                                lifestyle to keep up with their
                                                growth and energy.
                                        </p>
                                </TextBox>
                                <ImgBox></ImgBox>
                        </MaxWidthSection>
                </AboutSection>
        )
}

export default About
