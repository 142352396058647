import styled, { keyframes } from 'styled-components'

export const growImage = keyframes`
  0% {
    background-position: 70% 70%;
  }
  100% {
    background-position: 100% 80%;
  }
`
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const revealX = keyframes`
  from {
    opacity: 0;
    transform: translateX(-4rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`
export const revealY = keyframes`
  from {
    opacity: 0;
    transform: translateY(-4rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const shrinkHero = keyframes`
  0% {
    min-height: 100vh;
  }
  
  25% {
    min-height: 100vh;
  }
  to {
    min-height: 70vh;
  }
`
