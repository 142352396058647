const state = {
        maintenanceMode: false,
        testimonials: [
                {
                        name: 'Luke',
                        text: "I had an amazing experience with Janine at Movewell Physiotherapy! She's a true professional who helped me understand my back injury and correct years of bad movement patterns.",
                },
                {
                        name: 'Yani',
                        text: 'The wealth of knowledge and experience are the reasons why I am on the mend and on course to a full recovery.',
                },
                {
                        name: 'Yani',
                        text: 'Movewell offers great service and after service experiences.',
                },
                {
                        name: 'Maureen',
                        text: 'Janine explained in detail, the cause of my problem and how she would rectify and give me relief without surgery and medication. After about 6 sessions in the comfort of her rooms with deep therapy, I felt like a new person.',
                },
                {
                        name: 'Maureen',
                        text: 'Janine is lovely , thorough and professional and I highly recommended her.',
                },
                {
                        name: 'Noelle',
                        text: 'Janine explained everything so well that when you went home and did the exercises you knew exactly what you were doing and knew you were doing the right thing.',
                },
                {
                        name: 'René',
                        text: 'I had leg problems during the first day of my South Africa trip. I got really good and fast help!!! Wonderful, saved my trip! Thank you so much. Janine knows what she is doing!!!',
                },
        ],
        treatments: [
                {
                        treatmentName: 'Neck and back pain',
                        hasSub: false,
                        sub: '',
                },
                {
                        treatmentName: 'Peripheral joint pain',
                        hasSub: true,
                        sub: 'shoulders, hips, knees, ankles',
                },
                {
                        treatmentName: 'Tendinopathies',
                        hasSub: false,
                        sub: '',
                },
                {
                        treatmentName: 'Muscle strains',
                        hasSub: false,
                        sub: '',
                },
                {
                        treatmentName: 'Pre and postoperative surgery',
                        hasSub: true,
                        sub: 'Total hip or knee replacements, spinal surgery, shoulder surgery, fractures',
                },
                {
                        treatmentName: 'Headaches',
                        hasSub: false,
                        sub: '',
                },
                {
                        treatmentName: 'Overuse injuries due to sport',
                        hasSub: true,
                        sub: 'shin splints, groin pain, calf pain, rotator cuff injuries',
                },
                {
                        treatmentName: 'Running injuries',
                        hasSub: false,
                        sub: '',
                },
                {
                        treatmentName: 'General mobility issues',
                        hasSub: false,
                        sub: ' ',
                },
                {
                        treatmentName:
                                'Ergonomic workstation assessments and corrections',
                        hasSub: true,
                        sub: 'at both home and work offices',
                },
        ],
        expectations: [
                {
                        image: 'experience',
                        heading: 'Experience',
                        paragraph: 'We take a holistic approach, conducting a thorough assessment and diagnosis to uncover any dysfunction and lack of movement control at the root of your issue. Our goal is not only to address symptoms and pain but to deliver long lasting results. Please wear shorts for a clear analysis of your body’s movements.',
                },
                {
                        image: 'empowerment',

                        heading: 'Empowerment',
                        paragraph: "A detailed explanation of our findings, addressing all your questions will be provided. You'll receive an individualized treatment plan, including a tailored home exercise program to maximize the benefits of your treatment. We understand that one size does not fit all.",
                },
                {
                        image: 'building',

                        heading: 'Building and valuing relationships',
                        paragraph: 'We are committed to helping you on your movement journey and reaching your goals.',
                },
                {
                        image: 'trust',
                        heading: 'Trust',
                        paragraph: 'We are professionals that care, and are registered with HPCSA (Health professions council in South Africa) and HCPCUK (Health and care professions council in the UK). You can trust in our expertise and commitment to your well being.',
                },
        ],
}

export default state
