import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  50% {
    scale: 1 1.5;
  }
  to {
    transform: rotate(360deg);
  }
`

const Blob = styled.div`
        position: absolute;

        width: 40vw;
        height: 40vw;
        translate: -50% -50%;
        border-radius: 50%;
        background: linear-gradient(
                to right,
                ${(props) => props.theme.colors.brand.base},
                ${(props) => props.theme.colors.accent.base}
        );
        animation: ${rotate} 20s infinite;
        pointer-events: none;
        z-index: -1;
        opacity: 0.6;
        filter: blur(200px);
`

const MouseFollower = () => {
        const [position, setPosition] = useState({ x: 0, y: 0 })

        // set initial position with useEffect
        useEffect(() => {
                const blob = document.getElementById('blob')
                setPosition({
                        x: window.innerWidth / 2,
                        y: window.innerHeight / 2,
                })
        }, [])
        document.body.onpointermove = (e) => {
                let { clientX, clientY } = e
                blob?.animate(
                        {
                                top: `${clientY}px`,
                                left: `${clientX}px`,
                        },
                        {
                                duration: 6000,
                                fill: 'forwards',
                        }
                )
        }

        return (
                <Blob
                        positionX={position.x}
                        positionY={position.y}
                        id={'blob'}
                ></Blob>
        )
}

export default MouseFollower
