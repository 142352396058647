import { rgba } from 'polished'
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import styled from 'styled-components'
import logo from '../../../assets/Logo/Full/White.svg'

import imgPhone from '../../../assets/images/icons/Phone.png'
import { revealY } from '../styles/animations'
const NavContainer = styled.nav`
        ${({ theme, scrollTop }) => {
                if (!scrollTop) {
                        return `background:
                                        ${rgba(theme.colors.brand.base, 1)};
        ${theme.shadows.z1};

                                ;`
                } else {
                        return `background: linear-gradient(
                                        to bottom,
                                        ${rgba(
                                                theme.colors.foundation.black,
                                                0.4
                                        )},
                                        ${rgba(theme.colors.brand.dark, 0.01)}
                                );        
                                ${theme.shadows.z0};
                                `
                }
        }};
        opacity: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0%;
        width: 100%;
        z-index: 999 !important;
        flex-wrap: wrap;
        gap: 2em;
        row-gap: 0.5rem;
        padding: 0.5rem;
        transition: all 0.3s ease-in-out;
        transition: background 0.6s ease-in-out;
        animation: 0.5s ${revealY} ease-out;
        animation-delay: 0.5s;

        animation-fill-mode: forwards;

        .phoneLink {
                transition: all 400ms ease-in-out;
                gap: 0.5rem;

                :hover,
                :active {
                        ${({ theme }) => theme.shadows.active};
                        transition: all 200ms ease-in-out;
                        span {
                                max-width: 100%;
                        }
                        background-color: ${({ theme }) =>
                                theme.colors.accent.base};

                        border: 1px solid
                                ${({ theme }) =>
                                        rgba(theme.colors.foundation.white, 0)};
                }
                img {
                        width: 1.25rem;
                        height: auto;
                }
                border: 1px solid
                        ${({ theme }) => theme.colors.foundation.white};
                display: flex;
                align-items: center;

                border-radius: 1rem;
                padding: 0.45rem;
        }
`
const LogoContainer = styled.img`
        padding: 0 12px;
        width: 100%;
        min-width: 200px;
        ${({ theme, scrollTop }) => {
                if (!scrollTop) {
                        return `max-width: 200px;`
                } else {
                        return `max-width: 240px;`
                }
        }};
        transition: all 300ms ease-in-out;
`

const BottomNavContainer = styled.nav`
        background: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 51px 8px 160px 8px;
        background-color: ${({ theme }) => theme.colors.brand.base};
        flex-direction: column-reverse;
`
const BottomLogoContainer = styled.img`
        width: 175px;
        padding: 0 12px;
        margin: 48px;
`

const NavLinksContainer = styled.ul`
        list-style: none;
        display: flex;
        margin: 0;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.75rem;
        row-gap: 8px;
        padding: 0;
`
const LinkElement = styled(NavLink)`
        cursor: pointer;
        font-size: 11px;
        text-decoration: none;
        text-transform: uppercase;
        color: ${({ theme }) => rgba(theme.colors.foundation.white, 0.7)};
        font-family: movewell-light;
        transition: all 0.3s ease-in-out;

        & :hover,
        :active {
                color: ${({ theme }) => theme.colors.brand.light};
                padding-left: 8px;
                padding-right: 8px;
                color: ${({ theme }) => rgba(theme.colors.foundation.white, 1)};
        }
        & :active {
                font-weight: 900;
        }
`
const LocalLink = styled(NavLink).attrs({
        as: 'a',
})`
        cursor: pointer;
        font-size: 11px;
        text-decoration: none;
        text-transform: uppercase;
        color: ${({ theme }) => rgba(theme.colors.foundation.white, 0.7)};
        font-family: movewell-light;
        transition: all 0.3s ease-in-out;

        &:hover {
                color: ${({ theme }) => theme.colors.brand.light};
                padding-left: 8px;
                padding-right: 8px;
                font-weight: 900;
                color: ${({ theme }) =>
                        rgba(theme.colors.foundation.white, 0.9)};
        }
`

export const TopNav = () => {
        const [scrollTop, setScrollTop] = useState(true)
        useEffect(() => {
                const onScroll = (event) => {
                        if (window.pageYOffset > 20) {
                                return setScrollTop(false)
                        }
                        if (window.pageYOffset === 0) {
                                return setScrollTop(true)
                        }
                        return setScrollTop(true)
                }

                window.pageYOffset

                document.addEventListener('scroll', onScroll)

                return () => {
                        document.removeEventListener('scroll', onScroll)
                }
        }, [])
        useEffect(() => {}, [scrollTop])

        return (
                <NavContainer scrollTop={scrollTop}>
                        <LocalLink href="#hero">
                                <LogoContainer
                                        scrollTop={scrollTop}
                                        src={logo}
                                        alt="React Logo"
                                ></LogoContainer>
                        </LocalLink>

                        <NavLinksContainer>
                                <LocalLink href="#hero">home</LocalLink>
                                <LocalLink href="#vision">vision</LocalLink>
                                <LocalLink href="#about">about</LocalLink>
                                <LocalLink href="#whatToExpect">
                                        what to expect
                                </LocalLink>
                                <LocalLink href="#treatment">
                                        treatment
                                </LocalLink>

                                <LocalLink href="#contact">
                                        contact us
                                </LocalLink>
                                <LocalLink
                                        href="tel:+27637521811"
                                        className="phoneLink"
                                >
                                        <img src={imgPhone} alt="" />
                                        <span>+27 63 752 1811</span>
                                </LocalLink>
                        </NavLinksContainer>
                </NavContainer>
        )
}

export const BottomNav = () => {
        return (
                <BottomNavContainer>
                        <Link to="/">
                                <BottomLogoContainer
                                        src={logo}
                                        alt="React Logo"
                                ></BottomLogoContainer>
                        </Link>
                        <NavLinksContainer>
                                <LocalLink
                                        href="./pdf/terms-and-conditions.pdf"
                                        target="_blank"
                                >
                                        Terms & Conditions
                                </LocalLink>
                                <LocalLink
                                        href="./pdf/privacy-statement.pdf"
                                        target="_blank"
                                >
                                        Privacy Statement
                                </LocalLink>
                                <LocalLink
                                        href="./pdf/paia-manual.pdf"
                                        target="_blank"
                                >
                                        PAIA Manual
                                </LocalLink>
                        </NavLinksContainer>
                </BottomNavContainer>
        )
}

function Navigation({ children }) {
        return (
                <>
                        <TopNav></TopNav>
                        <section>{children}</section>
                        <BottomNav></BottomNav>
                </>
        )
}

export default Navigation
