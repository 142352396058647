const theme = {
    colors: {
        brand: {
            base: '#268C8B',
            dark: '#217877',
            light: '#2CA09E',
        },
        accent: {
            base: '#5674B6',
            dark: '#425D99',
            light: '#7B93C6',
        },
        foundation: {
            white: '#F8F8F8',
            black: '#041010',
            grey: '#D4D4D4',
            darkgrey: '#878787',
        },
        denotive: {
            success: '#88F072',
            warning: '#E53C3C',
            disabled: '#AEAEAE',
        },
    },
    sizing: '16',
    screens: {
        mobile: '550px',
        tablet: '768px',
        desktop: '1440px',
    },
    padding: {
        side: '24px',
        top: '72px',
    },
    shadows: {
        z1: `box-shadow: 0px 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
        z2: `box-shadow: 0px 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,
        z3: `box-shadow: 0px 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)`,
        z4: `box-shadow: 0px 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`,
        z5: `box-shadow: 0px 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)`,
        active: `box-shadow: 0px 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`,
    },
}

export default theme
