import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import Router from './components/Router'
import State from './components/State'
import { GlobalStyle } from './styles/GlobalStyle'
import theme from './styles/theme'
const App = () => {
        return (
                <React.StrictMode>
                        <ThemeProvider theme={theme}>
                                <GlobalStyle />
                                <State>
                                        <Router />
                                </State>
                        </ThemeProvider>
                </React.StrictMode>
        )
}
const appRoot = document.getElementById('root')

ReactDOMClient.createRoot(appRoot).render(<App />)
