import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import heroImage from '../../../assets/images/Hero.jpg'
import { fadeIn, growImage, revealX, shrinkHero } from '../styles/animations'

const Container = styled.div`
        min-height: 100vh;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(
                        180deg,
                        rgba(4, 16, 16, 0.5) 0%,
                        rgba(4, 16, 16, 0.1) 100%
                ),
                url(${heroImage});
        filter: FlipH;
        animation: 1s ${fadeIn} ease-out, 3s ${growImage} ease-out,
                2s ${shrinkHero} ease-out;
        animation-fill-mode: forwards;
        animation-delay: 1s;

        background-size: contain, cover;
        background-repeat: no-repeat;
        background-position: 0% 70%;

        h1,
        h2 {
                opacity: 0;
                color: ${({ theme }) => theme.colors.foundation.white};
                filter: drop-shadow(5px 5px 10px rgba(4, 16, 16, 0.4));
                animation: 2s ${revealX} ease-in-out;
                animation-delay: 1.5s;
                animation-fill-mode: forwards;
        }
        h2 {
                color: ${({ theme }) => theme.colors.foundation.white};
        }
        padding: 200px 0;
`
const ContentContainer = styled.div`
        /* center a div */
        width: min(90%, ${({ theme }) => theme.screens.desktop});

        /* padding: 12px 24px;
        @media (max-width: ${({ theme }) => theme.screens.mobile}) {
                padding: 160px 24px 80px 24px;
        } */
`

function Hero() {
        return (
                <Container id="hero">
                        <ContentContainer>
                                <h1>
                                        Dedicated to optimizing health and
                                        wellbeing through movement and education
                                </h1>
                        </ContentContainer>
                </Container>
        )
}

export default Hero
