import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import imgTreat1 from '../../../assets/images/Treat1.jpg'
import imgTreat2 from '../../../assets/images/Treat2.jpg'
import imgTreat3 from '../../../assets/images/Treat3.jpg'
import imgTreat4 from '../../../assets/images/Treat4.jpg'
import imgTreat5 from '../../../assets/images/Treat5.jpg'
import { useGlobalState } from './State'

const changeBg = keyframes`
  0%,100%  {background-image:  url(${imgTreat1});}
   20% {background-image: url(${imgTreat2});}
   40% {background-image: url(${imgTreat3});}
   60% {background-image: url(${imgTreat4});}
   80% {background-image: url(${imgTreat5});}
`

const TreatmentSection = styled.section`
        position: relative;
        min-height: 40rem;
        display: flex;

        animation: ${changeBg} 60s infinite;
        background-size: cover;
        background-position: center;
        justify-content: center;
        padding: max(5vh, 4rem) 0;
        .gradient {
                position: absolute;
                background-image: linear-gradient(
                        180deg,
                        rgba(248, 248, 248, 1) 0%,
                        rgba(248, 248, 248, 0.25) 50%
                );
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
        }
`
const MaxWidth = styled.div`
        width: min(90%, ${({ theme }) => theme.screens.desktop});
        display: flex;
        z-index: 4;
        flex-direction: column;
        & .intro {
                max-width: ${({ theme }) => theme.screens.tablet};
                h2 {
                        color: ${({ theme }) => theme.colors.brand.base};
                }
        }
        & > small {
                color: ${({ theme }) => theme.colors.foundation.black};
        }
`

const Pillbox = styled.div`
        display: flex;
        gap: 1rem;
        margin: 2rem 0;
        flex-direction: column;
        align-items: flex-start;

        & .pill {
                background-color: ${({ theme }) =>
                        theme.colors.foundation.white};
                border: 1px solid ${({ theme }) => theme.colors.brand.base};
                border-radius: 2rem;
                padding: 0.5rem 1.75rem;
                ${({ theme }) => theme.shadows.z1};
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                justify-content: center;
                small {
                        margin: 0px;
                        color: ${({ theme }) => theme.colors.brand.base};
                }
        }
`

function Treatment() {
        const [treatments, setTreatments] = useState()
        const globaleState = useGlobalState()

        useEffect(() => {
                let { treatments } = globaleState
                setTreatments(treatments)
        }, [])

        return (
                <TreatmentSection id="treatment">
                        <div className="gradient"></div>
                        <MaxWidth>
                                <div className="intro">
                                        <h2> Treatment</h2>
                                        <p>
                                                At Movewell physiotherapy we
                                                offer a combination of hands on
                                                modalities and rehabilitation,
                                                including soft tissue treatment
                                                and myofascial release, joint
                                                mobilisations, neural
                                                mobilisations, strapping and
                                                kinesio taping, and dry
                                                needling. Common conditions that
                                                we treat but are not limited to
                                                include:
                                        </p>
                                </div>
                                <Pillbox className="pillbox">
                                        {treatments?.map((treatment, i) => {
                                                return (
                                                        <div
                                                                key={i}
                                                                className="pill"
                                                        >
                                                                {
                                                                        treatment.treatmentName
                                                                }

                                                                {treatment.hasSub && (
                                                                        <small>
                                                                                {
                                                                                        treatment.sub
                                                                                }
                                                                        </small>
                                                                )}
                                                        </div>
                                                )
                                        })}
                                </Pillbox>
                                <small>
                                        *Home visits can be arranged on request.
                                </small>
                        </MaxWidth>
                </TreatmentSection>
        )
}

export default Treatment
