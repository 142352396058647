import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import imageGrain from '../../../assets/images/diagonal-noise.png'
import Footer from './Footer'
import Navigation from './Navigation'
const Container = styled.div`
        height: 100%;
        min-height: 100vh;
        margin: 0px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        * {
                z-index: 3;
        }
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  50% {
    scale: 1 1.5;
  }
  to {
    transform: rotate(360deg);
  }
`
const Blob = styled.div`
        background: linear-gradient(
                to right,
                ${(props) => props.theme.colors.brand.base},
                ${(props) => props.theme.colors.accent.base}
        );
        position: absolute;
        transform: translate(-50%, -50%);
        width: 40vw;
        height: 40vw;
        translate: -50% -50%;
        border-radius: 50%;
        animation: ${rotate} 10s infinite;
        pointer-events: none;
        z-index: 1;
        opacity: 0.7;
        filter: blur(20rem);
`
const Grain = styled.div`
        background-image: url(${imageGrain});
        mix-blend-mode: overlay;
        opacity: 0.3;
        position: absolute;
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
        z-index: 9999;
        pointer-events: none;
`
function Page({ children }) {
        useEffect(() => {
                const blob = document.getElementById('blob')
                document.body.onpointermove = (e) => {
                        const { clientX, clientY } = e

                        blob &&
                                blob.animate(
                                        {
                                                left: `${clientX}px`,
                                                top: `${clientY}px`,
                                        },
                                        {
                                                duration: 2000,
                                                fill: 'forwards',
                                        }
                                )
                }

                return () => {}
        }, [])

        return (
                <Container>
                        <Grain></Grain>
                        <Navigation>
                                {children}
                                <Footer />
                        </Navigation>
                        <Blob id="blob"></Blob>
                </Container>
        )
}

export default Page
