import { rgba } from 'polished'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
        width: min(90%, ${({ theme }) => theme.screens.desktop});
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 2rem;
        margin: max(5vh, 4rem) auto;

        h4,
        p {
                max-width: 40rem;
        }
        span {
                display: block;

                width: 7rem;
                height: 4px;
                background: ${({ theme }) => `linear-gradient(
                                        to right,
                                        ${rgba(theme.colors.brand.base, 1)},
                                        ${rgba(theme.colors.brand.base, 0.01)}
                                );`};

                margin-top: 2rem;
        }

        @media (max-width: ${({ theme }) => theme.screens.tablet}) {
                flex-direction: column;
        }
`
function Intro() {
        return (
                <Container>
                        <h4>
                                Movewell Physiotherapy is a musculoskeletal
                                physiotherapy practice with a special interest
                                in sports injuries and orthopaedic conditions.
                                <span></span>
                        </h4>
                        <p>
                                Our expertise lies in enhancing your lifelong
                                mobility and function. Whether you're facing
                                challenges in your sports performance, posture
                                from prolonged desk work, sleep disruptions due
                                to pain, or everyday tasks like carrying your
                                baby, we're here to assist. We are passionate
                                about helping you get back to what you enjoy and
                                preparing you for your next adventure – together
                                we’ve got this.
                        </p>
                </Container>
        )
}

export default Intro
