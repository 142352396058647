import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import logo from './../../../assets/Logo/Full/Black.png'
import MouseFollower from './MouseFollower'
import { useGlobalState } from './State'
const Container = styled.div`
    padding: 10vw;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const Content = styled.div`
    width: 100%;
    max-width: 600px;
`
const Logo = styled.img`
    height: auto;
    width: 100%;
    max-width: 300px;
    cursor: pointer;
`
const Display = styled.h1`
    color: ${({ theme }) => theme.colors.brand.base};
`
const TextInput = styled.input``
const ButtonInput = styled.input``
const Form = styled.form`
    margin: 30px 0;
    display: flex;
    gap: 8px;
    ${TextInput} {
        flex: auto;
    }
    label {
        display: none;
    }
`

const Links = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin-top: 2rem;
    li {
        margin: 5px 0;
        a {
        }
    }
`

export default function ComingSoonPage() {
    const globaleState = useGlobalState()
    // globaleState.update({ test: 'clicked' })

    const [email, setEmail] = useState('')
    function handleSubmit(event) {
        event.preventDefault()
        console.log(email)
    }

    return (
        <Container>
            <MouseFollower></MouseFollower>
            <Content>
                <Link to="/">
                    <Logo src={logo} alt="logo" />
                </Link>
                <Display>Coming Soon</Display>
                <p>
                    We are delighted to announce that a new website for Movewell
                    Physiotherapy is on the horizon! Stay tuned for more
                    information as we prepare to launch this exciting new
                    chapter. Thank you for choosing Movewell Physiotherapy!
                </p>
                {/* <Form onSubmit={handleSubmit}>
                    <label htmlFor="emailInput">Email:</label>
                    <TextInput
                        id="emailInput"
                        type="email"
                        value={email} // ...force the input's value to match the state variable...
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email@addresss.com"
                    />
                    <ButtonInput type="submit" value="Notify Me" />
                </Form> */}

                <Links>
                    <li>
                        <a href="tel:+27637521811">+27 (0)63 752 1811</a>
                    </li>
                    <li>
                        <a href="mailto:hello@movewellphysio.co.za">
                            hello@movewellphysio.co.za
                        </a>
                    </li>
                    {/* <li>
                        <a href="http://facebook.com">@movewellphysio</a>
                    </li> */}
                </Links>
            </Content>
        </Container>
    )
}
