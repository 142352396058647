import { rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'
export const GlobalStyle = createGlobalStyle`

/* ### Base Styles ### */

html{
    scroll-behavior: smooth;
    scroll-padding-top: 20vh;
}
    body{
        background-color: ${({ theme }) => theme.colors.foundation.white};
        font-size: ${({ theme }) => theme.sizing}px;
        margin: 0px;    
    }
    * {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        color: ${({ theme }) => theme.colors.black};
        font-family: "movewell-regular", sans-serif;

    }
    ::selection{
    background: ${({ theme }) => rgba(theme.colors.brand.light, 0.6)};
    }
    img::selection{
    background: transparent;
    }


/* ### Typography ### */
    .type__bold,h2,h4{
        font-family: "movewell-bold", sans-serif;
        font-weight: 900;
    }
    .type__regular,p, button, .button, small, .caption{
        font-family: "movewell-regular", sans-serif;
        font-weight: 400;
    }
    .type__light, h1, h3{
        font-family: "movewell-light", sans-serif;
        font-weight: 100;
    }
    h1, h2, h3, h4, h5, p, small, a {
        margin-top: 0px;
        line-height: 1.5;
        margin-bottom: .8rem;
        letter-spacing: 0.02em;
    }
    h1 {
        font-size: clamp(1.8rem, 1rem + 5vw, 3rem);
    }
    h2 {
        font-size: clamp(1.6rem, 1rem + 4vw, 2rem);
    }
    h3 {
        font-size: clamp(1.4rem, 1rem + 3vw, 1rem);
    }
    h4 {
        font-size: clamp(1.2rem, 1rem + 2vw, 1rem);
    }
    p {

    }
    small, .caption {
        font-size: .8rem;
    }
    button, .button {
        text-transform: uppercase;
        font-size: 0.8rem;
    }
    a, .anchor{
        margin: 0px;
        color: ${({ theme }) => rgba(theme.colors.accent.base, 1)};
        &:hover{
        color: ${({ theme }) => rgba(theme.colors.accent.light, 1)};
        }
    }
`
