import React from 'react'
import ContactPage from './ContactPage'
import Testimonial from './Testimonial'

function Footer() {
        return (
                <>
                        <Testimonial />
                        <ContactPage />
                </>
        )
}

export default Footer
