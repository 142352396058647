import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import imgImprove from '../../../assets/images/Vision-Improve.jpg'
import imgPain from '../../../assets/images/Vision-Pain.jpg'
import imgRestore from '../../../assets/images/Vision-Restore.jpg'
const Container = styled.div`
        width: min(90%, ${({ theme }) => theme.screens.desktop});
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 2rem;

        margin: max(5vh, 4rem) auto;

        h2 {
                max-width: 50rem;
                text-align: left;
                font-weight: 400;
                align-self: flex-start;
        }
        p {
                max-width: 40rem;
                text-align: center;
                font-weight: 400;
        }
        span {
                font-weight: 900;
                color: ${({ theme }) => theme.colors.brand.base};
        }

        @media (max-width: ${({ theme }) => theme.screens.tablet}) {
                flex-direction: column;
        }
`
const CardContainer = styled.section`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        gap: 2rem;
`

const Card = styled.div`
        flex: 0 1 440px;
        max-width: 100%;
        transition: all 300ms ease;
        &:hover {
                transform: scale(1.02);
        }
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1.5rem;
        img {
                object-fit: contain;
                margin-bottom: 1.5rem;
                max-width: min(440px, 100%);
                border-radius: 0.25rem;
        }
`
function Vision() {
        return (
                <Container id="vision">
                        <h2>
                                <span>Our Vision </span>
                                is to optimize health through movement and
                                education by...
                        </h2>
                        <CardContainer>
                                <Card>
                                        <img src={imgPain} alt="" />
                                        <h4>Decreasing your pain</h4>
                                </Card>
                                <Card>
                                        <img src={imgRestore} alt="" />
                                        <h4>
                                                Restoring movement control and
                                                function including stability,
                                                strength and flexibility
                                        </h4>
                                </Card>
                                <Card>
                                        <img src={imgImprove} alt="" />
                                        <h4>
                                                Improving performance and
                                                quality of life
                                        </h4>
                                </Card>
                        </CardContainer>
                        <p>
                                Reaching your goals is our goal, and we strive
                                to reach these as efficiently as possible.
                                <br />
                                <br />
                                Feeling empowered and unrestricted in your
                                lifestyle, we want you to be able to move well
                                so that you can be confident in exploring
                                different and new activities. Move well, move
                                more, move without limits!
                        </p>
                </Container>
        )
}

export default Vision
