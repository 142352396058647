import React, { useState } from 'react'
import styled from 'styled-components'
import mapImage from '../../../assets/images/Contact-Map.jpg'
import iconAddress from '../../../assets/images/icons/Address.png'
import iconEmail from '../../../assets/images/icons/Email.png'
import iconFacebook from '../../../assets/images/icons/Facebook.png'
import iconInstagram from '../../../assets/images/icons/Instagram.png'
import iconPhone from '../../../assets/images/icons/Phone.png'

const ContactSection = styled.section`
        position: relative;
        min-height: 40rem;
        display: flex;
        background-image: url(${mapImage});
        background-position: 80% center;
        justify-content: center;
        padding: max(5vh, 4rem) 0;
`
const MaxWidth = styled.div`
        width: min(90%, ${({ theme }) => theme.screens.desktop});
        display: flex;
        justify-content: flex-end;
`

const ContactBox = styled.div`
        background-color: ${({ theme }) => theme.colors.foundation.white};
        max-width: 600px;
        padding: 2rem;
        border-radius: 0.25rem;
        ${({ theme }) => theme.shadows.z3};
        h2 {
                color: ${({ theme }) => theme.colors.brand.base};
        }
        ul {
                padding: 0;
                list-style: none;
        }
        overflow: hidden;
        @media (max-width: ${({ theme }) => theme.screens.mobile}) {
                h2,
                p {
                        text-align: center;
                }
                margin: 0;
                display: flex;
                flex-direction: column;
        }
`
const ContactDetails = styled.ul`
        display: flex;
        gap: 1rem;
        margin-top: 3rem;
        margin-bottom: 0;
        flex-wrap: wrap;
        gap: 2rem;
        a {
                width: 100%;
                text-decoration: none;
                color: ${({ theme }) => theme.colors.foundation.black};
                &:active,
                :hover {
                        color: ${({ theme }) => theme.colors.foundation.black};
                }
        }
        li {
                background-color: ${({ theme }) => theme.colors.brand.base};

                padding: 1rem;
                border-radius: 0.25rem;
                display: flex;
                gap: 3rem;
                flex: 1 1 450px;
                align-items: center;
                border: 1px solid
                        ${({ theme }) => theme.colors.foundation.black};
                ${({ theme }) => theme.shadows.z1};
                transition: all 400ms ease-in-out;
                &:active,
                :hover {
                        transition: all 200ms ease-in-out;
                        ${({ theme }) => theme.shadows.active};
                        background-color: ${({ theme }) =>
                                theme.colors.accent.base};
                }
                img {
                        margin: 0 1rem;
                        max-width: 32px;
                }
                h4,
                p {
                        margin: 0px;
                }

                @media (max-width: ${({ theme }) => theme.screens.mobile}) {
                        flex-direction: column;
                        gap: 1rem;
                        text-align: center;
                }
        }
`
const Socials = styled.ul`
        display: flex;
        gap: 2rem;
        margin-top: 3rem;
        margin-bottom: 0;
        justify-content: space-evenly;
        @media (max-width: ${({ theme }) => theme.screens.mobile}) {
                align-self: center;
                img {
                        max-width: 54px;
                }
        }
        img {
                max-width: 32px;
                transition: all 400ms ease-in-out;
                & :active,
                :hover {
                        transform: scale(1.1);
                        transition: all 200ms ease-in-out;
                }
        }
`

function ContactPage() {
        return (
                <ContactSection id="contact">
                        <MaxWidth>
                                <ContactBox>
                                        <h2>Contact Us</h2>
                                        <p>
                                                Not sure if you need physio? You
                                                are welcome to give us a call
                                                for a free 10 minute chat to
                                                discuss your concerns or
                                                requirements. As we are first
                                                line practitioners, you do not
                                                need a GP referral.
                                        </p>
                                        <ContactDetails>
                                                <a href="tel:+27637521811">
                                                        <li>
                                                                <img
                                                                        src={
                                                                                iconPhone
                                                                        }
                                                                        alt=""
                                                                />
                                                                <div>
                                                                        <h4>
                                                                                Phone:{' '}
                                                                        </h4>
                                                                        <p>
                                                                                +27
                                                                                63
                                                                                752
                                                                                1811
                                                                        </p>
                                                                </div>
                                                        </li>
                                                </a>
                                                <a href="mailto:hello@movewellphysio.co.za">
                                                        <li>
                                                                <img
                                                                        src={
                                                                                iconEmail
                                                                        }
                                                                        alt=""
                                                                />
                                                                <div>
                                                                        <h4>
                                                                                Email:{' '}
                                                                        </h4>
                                                                        <p>
                                                                                hello@movewellphysio.co.za{' '}
                                                                        </p>
                                                                </div>
                                                        </li>
                                                </a>
                                                <a
                                                        href="https://www.google.com/maps/place/Movewell+Physiotherapy/@-34.0359955,18.4587449,15z/data=!4m6!3m5!1s0x1dcc43896c60e6bd:0x97980015c5178375!8m2!3d-34.0359955!4d18.4587449!16s%2Fg%2F11khrkl3tx?entry=ttu"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                >
                                                        <li>
                                                                <img
                                                                        src={
                                                                                iconAddress
                                                                        }
                                                                        alt=""
                                                                />
                                                                <div>
                                                                        <h4>
                                                                                Address:{' '}
                                                                        </h4>

                                                                        <p>
                                                                                11
                                                                                Heather
                                                                                Way,
                                                                                Meadowridge,{' '}
                                                                                <br />
                                                                                Cape
                                                                                Town,
                                                                                South
                                                                                Africa
                                                                        </p>
                                                                </div>
                                                        </li>
                                                </a>
                                        </ContactDetails>
                                        <Socials>
                                                <a
                                                        href="https://www.facebook.com/profile.php?id=100092719866567"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                >
                                                        <li>
                                                                <img
                                                                        src={
                                                                                iconFacebook
                                                                        }
                                                                        alt=""
                                                                />
                                                        </li>
                                                </a>
                                                <a
                                                        href="https://www.instagram.com/movewell_physio/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                >
                                                        <li>
                                                                <img
                                                                        src={
                                                                                iconInstagram
                                                                        }
                                                                        alt=""
                                                                />
                                                        </li>
                                                </a>
                                        </Socials>
                                </ContactBox>
                        </MaxWidth>
                </ContactSection>
        )
}

export default ContactPage
