import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import About from './About'
import Hero from './Hero'
import Intro from './Intro'
import Page from './Page'
import Treatment from './Treatment'
import Vision from './Vision'
import WhatToExpect from './WhatToExpect'

function LandingPage() {
        return (
                <Page>
                        <Hero />
                        <Intro />
                        <Vision />
                        <About />
                        <WhatToExpect />
                        <Treatment />
                </Page>
        )
}

export default LandingPage
