import { rgba } from 'polished'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useGlobalState } from './State'

const Section = styled.section`
        background-color: ${({ theme }) => theme.colors.foundation.grey};
        padding: max(5vh, 4rem) 0;
        min-height: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        ${({ theme }) => theme.shadows.z4};
`
const Container = styled.div`
        width: min(90%, ${({ theme }) => theme.screens.desktop});
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 2rem;
        margin: 0 auto;
        transition: opacity 700ms ease-in-out;

        opacity: 1;

        ${({ loading }) => {
                if (loading) {
                        return `opacity: 0;`
                }
                if (!loading) {
                        return `opacity: 1;`
                }
        }};

        h3 {
                max-width: 60rem;
                text-align: center;
        }
        p {
                max-width: 60rem;
                text-align: center;
        }
        img {
                width: 100%;
        }

        @media (min-width: ${({ theme }) => theme.screens.tablet}) {
                flex-direction: row;
                img {
                        max-width: 30rem;
                }
        }
        @media (max-width: ${({ theme }) => theme.screens.tablet}) {
                h3 {
                        font-size: 1.25rem;
                }
        }
`

function Testimonial() {
        const [testimonial, setTestimonial] = useState({})
        const [loading, setLoading] = useState(true)
        const globaleState = useGlobalState()

        function pickRandomNumber(max) {
                let min = 0
                let top = max
                return Math.floor(Math.random() * (max - min) + min)
        }

        function updateTestimonial() {
                let NewTestimonial =
                        globaleState?.testimonials[
                                pickRandomNumber(
                                        globaleState.testimonials.length
                                )
                        ]
                return NewTestimonial
        }

        useEffect(() => {
                if (!loading) {
                        setTimeout(() => {
                                setLoading(true)
                        }, 10000)
                }
                if (loading) {
                        setTimeout(() => {
                                setTestimonial(updateTestimonial())
                        }, 1000)
                        setTimeout(() => {
                                setLoading(false)
                        }, 2000)
                }
        }, [loading])

        return (
                <Section>
                        <Container loading={loading}>
                                <section>
                                        <h3>"{testimonial.text}"</h3>
                                        <p>
                                                <strong>
                                                        {testimonial.name}
                                                </strong>
                                        </p>
                                </section>
                        </Container>
                </Section>
        )
}

export default Testimonial
