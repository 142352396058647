import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import imageBuilding from '../../../assets/images/Expect-Building.jpg'
import imageEmpowerment from '../../../assets/images/Expect-Empowerment.jpg'
import imageExperience from '../../../assets/images/Expect-Experience.jpg'
import imageTrust from '../../../assets/images/Expect-Trust.jpg'
import { useGlobalState } from './State'

const WhatToExpectSection = styled.section`
        position: relative;
        min-height: 20rem;
        display: flex;
        justify-content: center;
        background-image: linear-gradient(
                180deg,
                rgba(212, 212, 212, 1) 0%,
                rgba(212, 212, 212, 1) 25%,
                rgba(248, 248, 248, 1) 50%,
                rgba(248, 248, 248, 1) 100%
        );
`

const MaxWidthSection = styled.div`
        width: min(90%, ${({ theme }) => theme.screens.desktop});
        margin: max(5vh, 4rem) auto;

        display: flex;
        flex-wrap: wrap;
        @media (max-width: ${({ theme }) => theme.screens.tablet}) {
                flex-direction: column;
        }
        h2 {
                color: ${({ theme }) => theme.colors.brand.base};
                width: 100%;
                text-align: left;
        }
        gap: 2rem;
`

const ExpectationBox = styled.div`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(min(50rem, 100%), 1fr));
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;

        * {
                transition: background-color 300ms ease-in-out;
        }
`
const Expectation = styled.div`
        flex: 1 1 490px;
        /* background-color: ${({ theme }) => theme.colors.foundation.white}; */

        /* border: 1px solid ${({ theme }) => theme.colors.brand.base}; */
        border-radius: 0.25rem;
        &:hover {
                .img {
                        transition: all 300ms ease-in-out;

                        ${({ theme }) => theme.shadows.z3};
                }
        }

        display: flex;
        :nth-child(odd) {
                flex-direction: row-reverse;
        }
        @media (max-width: ${({ theme }) => theme.screens.tablet}) {
                flex-direction: column;
                :nth-child(odd) {
                        flex-direction: column;
                }
        }
        gap: 4rem;
        .img {
                ${({ theme }) => theme.shadows.z1};
                transition: all 300ms ease-in-out;

                width: 100%;
                min-height: 450px;
                flex: 1;
                background-position: center;
                ${(props) => {
                        switch (props.image) {
                                case 'experience':
                                        return `background-image: url(${imageExperience});`
                                case 'empowerment':
                                        return `background-image: url(${imageEmpowerment});`
                                case 'building':
                                        return `background-image: url(${imageBuilding});
                background-position: center right;

                                        `
                                case 'trust':
                                        return `background-image: url(${imageTrust});`
                        }
                }};
                background-size: cover;
        }
        .content {
                flex: 1;
                /* padding: 2rem; */
                display: flex;
                flex-direction: column;
                justify-content: center;
        }
`
function WhatToExpect() {
        const [expects, setExpectations] = useState()
        const globaleState = useGlobalState()

        useEffect(() => {
                let { expectations } = globaleState
                setExpectations(expectations)
        }, [])
        return (
                <WhatToExpectSection id="whatToExpect">
                        <MaxWidthSection>
                                <h2>What To Expect</h2>
                                <ExpectationBox>
                                        {expects?.map((expect, i) => {
                                                return (
                                                        <Expectation
                                                                key={i}
                                                                image={
                                                                        expect.image
                                                                }
                                                        >
                                                                <div className="img"></div>
                                                                <div className="content">
                                                                        <h4>
                                                                                {
                                                                                        expect.heading
                                                                                }
                                                                        </h4>
                                                                        <p>
                                                                                {
                                                                                        expect.paragraph
                                                                                }
                                                                        </p>
                                                                </div>
                                                        </Expectation>
                                                )
                                        })}
                                </ExpectationBox>
                        </MaxWidthSection>
                </WhatToExpectSection>
        )
}

export default WhatToExpect
