import React, { createContext, useContext, useState } from 'react'
import state from '../state'
const StateContext = createContext()

export function useGlobalState() {
        return useContext(StateContext)
}

function State({ children }) {
        const [globaleState, setGlobalState] = useState(state)

        const update = (update) => {
                setGlobalState({ ...globaleState, ...update })
        }

        const value = {
                ...globaleState,
                update,
        }

        return (
                globaleState && (
                        <StateContext.Provider value={value}>
                                {children}
                        </StateContext.Provider>
                )
        )
}

export default State

// import { useGlobalState } from './State'
// const globaleState = useGlobalState()
// globaleState.update({ test: 'clicked' })
